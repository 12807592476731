import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { validateEmail } from './common/validations'
import axios from 'axios'

class Login extends Component {
  state = {
    email: '',
    success: null,
    error: null
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get('error') == 'not_authorized') {
      this.setState({ error: 'No tienes permiso para acceder a esa página.' });
    }
  }

  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  handleSubmit = event => {
    this.setState({ error: null, success: null })
    const email = this.state.email
    if (!email) this.setState({ error: 'Debe ingresar un correo.' })
    else if (!validateEmail(email)) this.setState({ error: 'Debe ingresar un email válido.' })
    else {
      axios.post('/api/v1/applicant/generate_login', { email })
        .then(res => {
          this.setState({ success: (`Hemos enviado el link de acceso a la siguiente dirección: ${email}`) });
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.setState({ error: 'Este email no se encuentra registrado en KeyGames.' });
          } else {
            this.setState({ error: error.response.data.messages });
          }
        });
    }
    event.preventDefault();
  }

  render() {
    const success = (this.state.success === null ? null : <div className={'alert alert-success horizontal-center'} role="alert"> {this.state.success} </div>)
    const error = (this.state.error === null ? null : <div className={'alert alert-danger horizontal-center'} role="alert"> {this.state.error} </div>)

    return (
      <div className='screen-container'>
        <div className='login-image'>
          <div className='login-image-text'>
            <p>Bienvenido<br />
            a KeyGames</p>
          </div>
        </div>
        <div className='vertical-center horizontal-center'>
          <div className='login-form container center-text'>
            {success}{error}
            <h2>Ingreso KeyGames</h2>
            <p>Para saber qué organizaciones te han solicitado una prueba, ingresa tu e-mail. Te enviaremos un correo con los accesos</p>
            <form onSubmit={this.handleSubmit}>
              <div className='form-group'>
                <label className='float-left'>Email</label>
                <input className='form-control' value={this.state.email} onChange={this.handleChange} placeholder="Ingresa tu email" />
              </div>
              <button  type="submit" className='btn btn-primary' style={{ width: '100%' }}>Solicitar Ingreso</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Login />,
    document.body.appendChild(document.createElement('div')),
  )
})
